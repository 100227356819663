<template>
  <div class="flex justify-end mb-4">
    <asom-button
      text="Add User"
      variant="success"
      icon="user-add"
      @click="$router.push('/users/create')"
    />
  </div>
  <asom-alert v-if="error" variant="error" :error-message="error" />
  <div class="w-full text-center" v-if="isLoading">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <asom-server-table
    :url="url"
    :columns="['no', 'personNo', 'name', 'status', 'station', 'roles', 'action']"
  >
    <template v-slot:header_station>
      Assigned Station
    </template>
    <template v-slot:no="scopedSlots">
      {{ scopedSlots.rowIndex + 1 }}
    </template>
    <template v-slot:roles="scopedSlots">
      {{
        scopedSlots.rowData.roles && scopedSlots.rowData.roles.join(", ")
      }}
    </template>
    <template v-slot:station="scopedSlots">
      {{
        formatStationDisplay(scopedSlots.rowData)
      }}
    </template>
    <template v-slot:action="scopedSlots">
      <div class="flex flex-col gap-1 w-48">
        <asom-button
          text="Change Roles"
          @click="
            $router.push({
              name: 'ChangeUserRole',
              params: scopedSlots.rowData,
            })
          "
        />
        <asom-button
          text="User Stations"
          @click="
            $router.push({
              name: 'userStations',
            });
            $store.commit('display/userStationSet',scopedSlots.rowData)
          "
        />
        
        <asom-button
          text="Edit User Details"
          variant="secondary"
          @click="
            $router.push({
              name: 'EditUserDetails',
              params: scopedSlots.rowData,
            })
          "
        />
        <asom-button
          v-if="!scopedSlots.rowData.isActivate || scopedSlots.rowData.isResigned"
          outline
          variant="success"
          text="Activate Account"
          @click="openActivateModal(scopedSlots.rowData)"
        />
        <asom-button
          v-else
          outline
          variant="error"
          text="Deactivate"
          @click="openDeactivateModal(scopedSlots.rowData)"
        />
      </div>
    </template>
  </asom-server-table>
    <asom-modal
      title="Confirm Deactivation"
      v-model="showModal"
      :dismissible="false"
    >
      <div class="space-y-4">
        <div>
          <p>Are you sure to deactivate account <strong>{{selectedUser.name}}</strong>?</p>
        </div>
        <div class="sm:flex sm:flex-row-reverse gap-2">
          <asom-button
            variant="error"
            text="Confirm"
            :disabled="isDeactivating"
            :loading="isDeactivating"
            @click="deactivate"
          />
          <asom-button
            outline
            variant="secondary"
            text="Cancel"
            :disabled="isDeactivating"
            :loading="isDeactivating"
            @click="closeDeactivateModal"
          />
        </div>
      </div>
      
    </asom-modal>
    <asom-modal
      title="Confirm Activation"
      v-model="showActivateModal"
      :dismissible="false"
    >
      <div class="space-y-4">
        <div>
          <p>Are you sure to activate account <strong>{{selectedUser.name}}</strong>?</p>
          
        </div>
        <div class="sm:flex sm:flex-row-reverse gap-2">
          <asom-button
            variant="error"
            text="Confirm"
            :disabled="isActivating"
            :loading="isActivating"
            @click="activate"
          />
          <asom-button
            outline
            variant="secondary"
            text="Cancel"
            :disabled="isActivating"
            :loading="isActivating"
            @click="closeActivateModal"
          />
        </div>
      </div>
      
    </asom-modal>
</template>

<script>
import { LIST_USERS_FOR_ADMIN } from "@/constants/apis";
import get from "lodash.get";
import isEmpty from 'lodash.isempty';
import { deactivateUser, activateUser } from "@/services/user.service";

export default {
  name: "Users",
  data() {
    return {
      users: [],
      isLoading: false,
      error: "",
      showModal: false,
      isDeactivating: false,
      selectedUser: {},
      isActivating: false,
      showActivateModal: false,
    };
  },
  computed: {
    url() {
      return LIST_USERS_FOR_ADMIN;
    }
  },
  methods: {
    formatStationDisplay({ station, line }, result = "") {
      if (!isEmpty(station)) {
        result = station;
        if (!isEmpty(line))
          result += ` (${line})`;
      }
      return result;
    },
    openDeactivateModal(user) {
      this.selectedUser = user;
      this.showModal = true;
    },
    closeDeactivateModal() {
      this.showModal = false;
    },
    openActivateModal(user) {
      this.selectedUser = user;
      this.showActivateModal = true;
    },
    closeActivateModal() {
      this.showActivateModal = false;
    },
    async deactivate() {
      this.isDeactivating = true;
      const resp = await deactivateUser(this.selectedUser.id);
      if (resp && resp.success)
        window.location.reload();
      else {
        this.error = get(resp, 'payload', ""); 
        this.$scrollTop();
      }
      this.showModal = false;
      this.isDeactivating = false;
    },
    async activate() {
      this.isActivating = true;
      const resp = await activateUser(this.selectedUser.id);
      if (resp && resp.success)
        window.location.reload();
      else {
        this.error = get(resp, 'payload', ""); 
        this.$scrollTop();
      }
      this.showActivateModal = false;
      this.isActivating = false;
    }
  }
};
</script>
